@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
}
#link-card-buttons {
	background: transparent;
	display: flex;
	flex-direction: column;
}

#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

.homepage {
	background-color: black !important;

	.mobile-homepage-banner {
		width: 100%;
		margin-top: -270px;
	}

	.container {
		padding: 25px;
	}

	h1, h2 {
		color: white;
		font-weight: 400;
		font-family: "Uber-Move-Medium";
		padding-bottom: 12px;
	}
	h3 {
		color: white;
		font-size: 16px;
		font-weight: 400;
		font-family: "Uber-Move-Light";
	}
	
	.render-cards-container {
		justify-content: space-around;
		width: 100%;
	}

	.subheader-vc-row {
		padding-top: 40px;
	}

	.vc-toggle-buttons {
		padding-top: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 0;

		.row-counter {
			color: white;
			padding-right: 12px;
		}

		img {
			padding-left: 10px;
		}
	}
	.no-padding{
		padding: 0px;
	}
	@media (min-width: 768px) {
		font-size: 16px;
		background-image: url("../../assets/images/banner.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding: 0;
		height: 914px;
		box-sizing: border-box;

		.vc-toggle-buttons {
			padding-right: 12px;
		}
		#link-card-buttons {
			background: transparent;
			display: flex;
			flex-direction: row;
		}
		#link-card-buttons > *:first-child {
			margin-left: 0;
		}
		#link-card-buttons > *:last-child {
			margin-right: 0;
		}
	}
	@media(min-widht: 501px) {

		#link-card-buttons {
			algin-items: center;
		}
	}
	@media (max-width: 768px) {
		.bottom-container{
			padding: 0px;
			margin: 10px;
		}
		.container{
			h1{
				font-size: 24px;
				text-align: center;
			}
			h2{
				font-size: 36px;
			}
			h3{
				font-size: 14px;
				margin-bottom: 20px
			}
		}
		#link-card-buttons {
			background: transparent;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}