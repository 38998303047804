@import "src/client_customizations/scss/client_master.scss";


/* evc-card */
/* Card Layouts */
h1 {
	font-weight: 600;
}
h2 {
	font-weight: 600;
}
h3 {
	font-weight: 600;
}
h4 {
	font-weight: 600;
}
h5 {
	font-weight: 600;
}
.h1 {
	font-weight: 600;
}
.h2 {
	font-weight: 600;
}
.h3 {
	font-weight: 600;
	strong {
		color: $PageLayout-header-bold-color;
	}
}
.h4 {
	font-weight: 600;
	strong {
		color: $PageLayout-header-bold-color;
	}
}
.h5 {
	font-weight: 600;
}
strong {
	font-weight: 600;
}
a {
	&:focus {
		box-shadow: $PageLayout-link-focus-box-shadow;
		outline: 0;
	}
}
button, .btn {
	&:focus {
		box-shadow: $PageLayout-button-focus-box-shadow;
		outline: 0;
	}
}
#select-vehicle-age-filter,#select-vehicle-make-filter {
    appearance: none;
	background: url(../../assets/images/icons/up-down-arrow.svg);
	background-position: calc(100% - .5rem), 100% 0;
  	background-size:  .75em .75em;
  	background-repeat: no-repeat;
	&:focus {
		box-shadow: $PageLayout-form-control-focus-box-shadow;
		outline: 0;
	}
}
.form-control {
	appearance: none;
	background: url(../../assets/images/icons/Triangle.svg);
	background-position: 95%;
	font-size: 13px;
  	background-size:  .5em .5em;
  	background-repeat: no-repeat;
	background-color: $white;
}

input {
	&:focus {
		box-shadow:$PageLayout-input-focus-box-shadow;
		outline: 0;
	}
}
select {
	&:focus {
		box-shadow: $PageLayout-select-focus-box-shadow;
		outline: 0;
	}
}
textarea {
	&:focus {
		box-shadow: $PageLayout-textarea-focus-box-shadow;
		outline: 0;
	}
}
.image-gallery-thumbnail {
	&:focus {
		box-shadow:$PageLayout-image-gallery-thumbnail-focus-box-shadow;
		outline: 0;
	}
}
.SimpleCopyPageBody {
	.card {
		&:focus {
			box-shadow: $PageLayout-SimpleCopyPageBody-card-focus-box-shadow;
			outline: 0;
		}
		background-color:$PageLayout-SimpleCopyPageBody-card-background-color;
		&:hover {
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
		}
		&:-webkit-any-link {
			&:hover {
				color:$PageLayout-SimpleCopyPageBody-card-link-hover-color;
				text-decoration: none;
			}
		}
		a {
			&:hover {
				color: $PageLayout-SimpleCopyPageBody-card-link-hover-color;
				text-decoration: none;
			}
		}
	}
	padding-bottom: 400px;
	p.h4 {
		color: $PageLayout-SimpleCopyPageBody-card-header-color;
		margin-top: 30px;
		font-weight: 500;
	}
}
.evc-card {
	&:focus {
		box-shadow: $focus-box-shadow;
		outline: 0;
	}
	background-color: $PageLayout-evc-card-focus-box-shadow;
	font-size: $PageLayout-evc-card-font-size;
	border: $PageLayout-evc-card-border;
	border-radius: 4px;
	color:$PageLayout-evc-card-color;
	width: 100%;
	display: inline-block;
	cursor: pointer;
	&:hover {
		border: $PageLayout-evc-card-hover-border;
		box-shadow: $PageLayout-evc-card-hover-box-shadow;
		color: $PageLayout-evc-card-hover-color;
		text-decoration: none;
	}
}
.SocialMediaShareButton {
	&:focus {
		box-shadow: $PageLayout-socialmedia-focus-box-shadow;
		outline: 0;
	}
}
.clickable-faq {
	&:focus {
		box-shadow: $PageLayout-faq-focus-box-shadow;
		outline: 0;
	}
	color: $PageLayout-faq-focus-color;
	display: block;
	text-decoration: none;
	padding: 1rem;
	cursor: pointer;
}
.evc-card.selected {
	box-shadow: none;
}
#logo {
	&:focus {
		box-shadow: none;
	}
}
.PageLayout {
	a {
		&:hover {
			text-decoration: none;
		}
	}
}
section.container {
	padding: 2rem 1rem;
	background-color: $PageLayout-container-background-color;
}


.dropdown-container {
	display: none;
}

.jumbotron-dropdown-button{
		cursor: pointer;
		text-decoration: none;
		background: none;
		border: none;
		font: Arial;
		outline: none;
		font-weight: 700;
		margin-left: 15px;
		
}

.down-arrow {
	height: 6px;
	filter: drop-shadow(0 0 8px black);   
	margin-right: 10px; 
	vertical-align: middle;  
}

.jumbotron-dropdown {
	padding-left: 75px;
	background-color: #f3f4f4;
	max-width: 1140px;
	margin: auto;
	border-bottom: 1px solid #E8E8E8;
	
}
.dropdown-button:focus {
	outline: none;
}

.show-sources-dropdown {
	display: flex;
	margin-left: 25%;
	border-bottom: 1px solid #E8E8E8;
}

.hide-offscreen {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
}
.clear-bottom-margin {
	margin-bottom: 0 !important;
}
.small-bottom-margin {
	margin-bottom: 5px !important;
}
.legal-disclaimer {
	font-size: 10px;
	letter-spacing: 0;
	color: $PageLayout-legal-disclaimer-color;
}
.sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 50px;
}
.renderRowOfData {
	p {
		line-height: 1.2em;
		span {
			float: right;
			font-weight: 500;
		}
		small {
			font-family: "Uber-Move-Light";
			font-weight: 600;
		}
	}
}
.RenderItemTitle {
	font-family: "Uber-Move-Light";
}
.h6 {
	strong {
		color: $PageLayout-header-strong-color;
	}
}
.SimpleCopyPageHeader {
	color: $PageLayout-SimpleCopy-color;
}
a.supporting-link {
	color: $PageLayout-SimpleCopy-color;
	margin-top: 0;
	font-weight: 700;
	font-size: 12px;
}
.evc-card.not-selected {
	height: 200px;
}
a.evc-card {
	&:hover {
		border: $PageLayout-evc-card-hover-border;
		box-shadow:$PageLayout-evc-card-hover-box-shadow;
		color: $PageLayout-evc-card-hover-color;
		text-decoration: none;
	}
}
.render-cards-container {
	display: flex;
	flex-wrap: wrap;
	.evc-card {
		width: 48%;
		margin: 1%;
	}
}

.centered {
	margin: auto !important;
}

.disclaimer {
	font-family: "Uber-Move-Light";
	margin-top: 10px;
	margin-left: 2px;
	font-size: 11px;
}
@media all and (max-width: 400px){
	.smaller{
		font-size: 8px;
		}
}

@media (min-width: 768px) {
	section.container {
		padding: 2rem 2rem;
	}
}
@media (min-width: 992px) {
	section.container {
		padding: 1rem 3rem;
	}
	.render-cards-container {
		.evc-card {
			width: 49%;
			margin: 1%;
		}
		>* {
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
        			margin-left: 0;
			}
			&:first-child {
				margin-top: 0;
			}
			&:nth-child(2) {
				margin-top: 0;
			}
		}
	}
	.render-cards-container.full-width {
		.evc-card {
			width: 23.5%;
			margin: 1%;
		}
		>* {
			&:nth-of-type(4n) {
				margin-right: 0;
			}
			&:nth-of-type(4n +1) {
        margin-left: 0;
			}
		}
	}
}

@media (min-width: 800px) {
	.chart {
		font-size: 10px;
	}
}
@media (min-width: 1200px) {
	.evc-card.not-selected {
		height: 220px;
	}
	.render-cards-container {
		.evc-card {
			width: 32%;
			margin: 1%;
		}
		>* {
			&:nth-of-type(3n) {
				margin-right: 0;
			}
			&:nth-of-type(3n+1) {
					margin-left: 0;
      }			
			&:first-child {
				margin-top: 0;
			}
			&:nth-child(2) {
				margin-top: 0;
			}
			&:nth-child(3) {
				margin-top: 0;
			}
		}
	}
}
