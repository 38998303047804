@font-face {
  font-family: Uber-Move-Bold;
  src: url("../assets/fonts/UberMove-Bold.otf")
    format("opentype");
}
@font-face {
  font-family: Uber-Move-Light;
  src: url("../assets/fonts/UberMove-Light.otf")
    format("opentype");
}
@font-face {
  font-family: Uber-Move-Medium;
  src: url("../assets/fonts/UberMove-Medium.otf")
    format("opentype");
}
@font-face {
  font-family: Uber-Move-Regular;
  src: url("../assets/fonts/UberMove-Regular.otf")
    format("opentype");
}

