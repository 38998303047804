@import "src/client_customizations/scss/client_master.scss"; 

.EVCard p {
  width: 100%;
  margin-bottom: 0;
}

.class-badge {
  margin-right: 5px;
}

.EVCardTop, .EVCardBottom > * {
  padding: 8px;
}

.EVCardTop {
  position: relative;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .badge-locally-available, .badge-fuel-type {
    display: flex;
    justify-content: flex-end;
    float:right;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}

.EVCardBottom {
  border-top: $EVCard-EVCardBottom-border;
  display: flex;
  .monetarySymbol {
    position: relative;
    top: -5px;
    font-size: 12px;
  }

  > * {
    flex-grow: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  div:first-child {
    border-right: $EVCard-EVCardBottom-border;
  }
}

.EVCardBottomWithLink {
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

@media (min-width: 576px) {
  .EVCardTop {
    padding: 15px 15px 10px 15px;
  }

  .EVCardBottom > * {
    padding: 10px 15px;
  }

  .EVCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}
@media all and (max-width: 500px) {
  .EVCardTop {
    .h2 {
      font-size: 14px;
    }
    .modal-title{
      font-size: 10px;
    }
    .badge-fuel-type, .badge-locally-available {
      img {
        width: 19px !important;
        padding: 0; 
      }
    }
  }
}
@media all and (max-width: 370px) {
  .class-badge {
    margin-right: 3px;
  }
  .EVCardTop {
    .badge-fuel-type{
      top: 6px !important;
      right: 6px !important;
    }
    .h2 {
      font-size: 12px;
    }
    .h3{
      font-size: 11px;
    }
    .modal-title{
      font-size: 10px;
    }

    .badge-fuel-type, .badge-locally-available {
      img {
        width: 14px !important;
        padding: 0;
      }
    }
    .renderRowOfData{
      p {
        line-height: 1em;
        font-size: 12px;
        span {
          float: right;
          font-weight: 500;
        }
        small {
          font-family: "Uber-Move-Light";
          font-weight: 600;
          font-size: 11px;
        }
      }
    }
  }
}