$color_1: #07679b;
$color_2: #666666;
$color_3: #f15c2a;
$color_4: #fff;
$color_5: #000;
$color_6: rgb(39, 110, 240);
$font_family_1: "Uber-Move-Bold","Open Sans", Helvetica, sans-serif;
$font_family_2: "Uber-Move-Light";
$font_family_3: "Uber-Move-Regular";
$font_family_4: "Uber-Move-Medium";
$background_color_1: #fff;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #5ca4d1;
$background_color_5: #f3f4f4;
$background_color_6: #0071b7;
$background_color_7: #000;
$border_color_1: transparent;
$border_color_2: #000;
$border_color_3: lightgrey;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

body {
	background-color: $background_color_1;
	font-family: $font_family_4;
	font-size: 13px;
}
h1 {
	font-size: 35px;
}
.h1 {
	font-size: 35px;
}
h2 {
	font-size: 20px;
	font-family: $font_family_1;
}
.h2 {
	font-size: 20px;
	font-family: $font_family_1;
}
.modal-title {
	font-size: 16px;
	padding-bottom: 5px;
	font-family: $font_family_3;
}
h3 {
	font-size: 20px;
	font-family: $font_family_1;
}
.h3 {
	font-size: 20px;
	font-family: $font_family_1;
}
h4 {
	font-size: 12px;
}
.h4 {
	font-size: 12px;
}
h5 {
	font-size: 10px;
}
.h5 {
	font-size: 10px;
}

.lead {
	font-size: 16px;
	font-family: $font_family_2;
	padding-bottom: 10px;
}
a {
	color: $color_1;
}
.page-title {
	padding-top: 50px;
}

@media (max-width: 500px) {
	table {
		font-size: 10px;
	}
}
@media (max-width: 350px) {
	table {
		font-size: 6px;
	}
}
table {
	table-layout:fixed;
	tbody {
		th {
			font-weight: normal;
		}
		tr {
			th {
				&:first-child {
					word-break: break-word;
					text-align: left;
				}	
			}
		}
	}
	tfoot {
		tr {
			th {
				&:first-child {
					word-break: break-word;
					text-align: left;
				}
			}
		}
	}
}
table.table-bordered {
	tfoot {
		td {
			word-break: break-word;
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
		th {
			word-break: break-word;
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
	}
}
.text-center {
	.btn {
		// background-color: $background_color_5;
		display: flex;
		padding: 10px 40px 10px 40px;
		font-weight: 600;
		font-size: 12px;
		margin-left: 20px;
	}
	.btn-ae {
		padding: 15px 30px 15px 30px;
		font-size: 14px;
		font-family: $font_family_2;
	}
}
.EVIncentives {
	text-align: center;
	.btn {
		background-color: $background_color_7;
		display: inline-block;
		
	}
}


.arrow-image {
	height: 10px;
	width: 15px;
	margin-left: 5px;
}

.RenderItemValue {
	.denominator {
		position: relative;
		top: -3px;
	}
}


button.btn-sm {
	font-size: 80%;
}

.caretImg {
	max-width: 16px;
	margin-bottom: 6px;

	&:hover {
		cursor: pointer;
	}
}

.input-well {
	.button.btn-sm {
		font-size: 80%;
	}
	background-color: $background_color_2;
	padding: 25px;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	.form-group {
		margin-bottom: 1.5rem;
		p {
			margin-top: 8px;
			margin-bottom: 1rem;
			text-transform: lowercase;
		}
	}
	form {
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
			
		}
	}
	label {
		font-weight: 700;
		font-size: 12px;
		color: $color_5;
		// text-transform: uppercase;
		margin-bottom: 5px;
		margin-top: 10px;
	}
	.label-style {
		font-weight: 700;
		font-size: 12px;
		color: $color_2;
		// text-transform: uppercase;
		margin-bottom: 0;
	}
	.btn {
		background-color: $background_color_1;
		display: inline-block;
		background-size: 10px;
		padding: 10px 30px 10px 30px;
		font-weight: 600;
		font-size: 12px;
		border: #000;
	}
	.title{
		font-size: 14px;
		padding: 0px 0px 10px 0px;
	}   
	
	.btn.active {
		background-color: $background_color_4;
		border: 1px solid #5ca4d1;
	}
	.btn-block-container {
		.filter-label{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
    		height: 40px;
			position: relative;
			.name{
				font-size: 11px;
			}
			.switch{
				position: absolute;
				right: 0;
			}
		}
		.btn {
			display: block;
			width: 100%;
			margin-bottom: 6px;
			text-align: left;
			padding: 10px 0px;
			font-size: 14px;
			.badge {
				background-color: $background_color_2;
				margin-right: 5px;
				img {
					width: 20px;
					padding-right: 17px;
				}

			}
		}
	}
	.btn-grid-container {
		display: flex;
		flex-wrap: wrap;
		button {
			width: 48%;
			margin: 2%;
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
				margin-left: 0;
					}
		}
	}
	.btn-ae {
		padding: 6px 12px;
		border-radius: 0;
		border: 0;
		color: $color_4;
		font-weight: 600;
		background-color: $background_color_7;
	}
}

.User-Input-Filter{
	font-size: 14px;
	margin: 50px 0px;

	.title{
		margin: 25px 0px;
		font-weight: bold;
	}
	.text{
		display: flex;
		justify-content: space-between;
		margin: 25px 0px;
		width: 100%;
		font-weight: lighter;
	}
	.icon{
		font-size: 15px;
		margin-right: "1px"
	}
	.toggle{
		float: right;
		margin-left: 20px;
	}

}
.badge-class-type{
	float: right;
}

.white-btn-ae {
	background-color: $background_color_2;
	font-size: 12px;
	padding: 10px 20px 10px 20px;
	border: 1px solid $border_color_3;
}

.btn-default {
	border: 1px solid #ced4da;
}
strong {
	font-weight: 500;
}
hr {
	margin: 0;
	padding: 0;
}
.arrow-up {
	top: calc((1rem - 1px) * -1);
	position: absolute;
	display: block;
	width: 1rem;
	height: 1rem;
	margin: 0 0.3rem;
	&:before {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 0;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_1;
	}
	&:after {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 1px;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_2;
	}
}
.background-grey {
	background-color: $background_color_5;
}
.bottom-thick-margin {
	border-bottom: 2px solid $border_color_2;
}
.color-primary {
	color: $color_3;
}
.btn-ae {
	padding: 6px 12px;
	border-radius: 0;
	border: 0;
	color: $color_4;
	font-weight: 600;
	background-color: $background_color_7;
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_7;
	}
}
.PageLayout {
	a.btn-ae {
		&:hover {
			border-radius: 0;
			color: $color_4;
			font-weight: 600;
			text-decoration: none;
			background-color: $background_color_6;
		}
	}
}
a.btn-ae {
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}

.dropbtn{
		cursor: pointer;
		text-decoration: none;
		background: none;
		border: none;
		font: Arial;
		outline: none;
		font-weight: 700;
		margin-left: 15px;
}

.IncentiveCardBody {
}
.incentive-card-header {
	border-top: 10px solid black;
	align-self: flex-start;
	justify-self: flex-start;
	font-weight: 600;
	font-size: 14px;
	padding-top: 15px;
	text-align: center;	
}
#EVIncentives {
	background-color: white;
}
.card-title {
	font-size: 30px;
}
.padding-right{
	padding-right: 16px;
}
// .select-ae {
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	-ms-appearance: none;
// 	appearance: none;
// 	background-repeat: no-repeat;
// 	background-position: right 15px top 11px, right 10px top 1em;
// 	background-size: 15px 15px, 15px 15px;
// 	background-image: url(../../assets/images/icons/Link-arrow.png);
// }

button.bluebtn {
	border: none;
	background-color: #fff;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 10px;
    padding: 10px 20px 10px 20px;
    font-weight: 600;
	color: $color_6;
	margin-left: -25px;
	background-image: url(../../assets/images/icons/blue-arrow.svg);
}

.dropdown-btn {
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	background-color: $background_color_1;
	display: inline-block;
	background-image: url("https://tools.greencars.com/static/media/down-arrow-2.07659ddf.svg");
	background-repeat: no-repeat;
	background-position: 95%;
	background-size: 10px;
	padding: 10px;
	font-weight: 600;
	font-size: 12px;
	color: black;
	margin-top: 20px;
	width: 300px;
    margin-left: -15px;
}

.buttonActiveStyle {
	background-color: $color_5;
	color: $color_4;
}

.buttonInactiveStyle {
	background-color: $color_4;
	color: $color_5;
}
.padding-right{
	padding-right: 10px;
}

#vehicle-sort-dropdown {
	background: url(/static/media/Triangle.8e57f895.svg);
	background-position: 95%;
	background-size: .5em .5em;
    	background-repeat: no-repeat;
	background-color: $background_color_1;
}

.data-title {
	margin: 0px 0px 15px 2px;
}

.graph-title {
	font-family: $font_family_2;
	font-weight: 100;
	font-size: 15px;
}

.arrow {
	background-image: url("https://tools.greencars.com/static/media/down-arrow-2.07659ddf.svg");
	background-repeat: no-repeat;
	background-position: 95%;
	background-size: 10px;
}

.padded {
	padding: 20px 0px 20px 0px;
}
.CalcPanels {
	.nav-fill {
		flex-wrap: nowrap;
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS */
	.select-ae {
		background-size: 0;
	}
 }
