@import "src/client_customizations/scss/client_master.scss";

.ButtonGroup {
	.btn {
		background-color: $ButtonGroup-btn-background-color; // $background_color_1;
		color: $black;
		&:hover {
			background-color: $ButtonGroup-btn-active-background-color;
			color: $white;
		}
	}
	.btn.active {
		background-color: $ButtonGroup-btn-active-background-color;
		color:$white;
		border: none;

	}
}
