@import "src/client_customizations/scss/client_master.scss"; 

.ComparedVehicleToggle {
  padding-bottom: 20px;
    .btn-group {
    width: 100%;
    }
   .btn {
    flex: 1;
    line-height: 1em;
    background-color: $CompareVehicleToggle-MobileToggle-button-color;
    color: $CompareVehicleToggle-MobileToggle-button-fontcolor;
    padding: 1rem 0.75rem;
  }
    .btn.active {
    background-color: $CompareVehicleToggle-MobileToggle-button-color-active;
    color:$CompareVehicleToggle-MobileToggle-button-fontcolor-active;
  }
}

.Optionstitle {
  padding-bottom: 5px;
}
  