@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
}
.link-card {
	display: flex;
	flex-shrink: 1;
	cursor: pointer;
	padding: 36px;
  width: 250px;
	height: 220px;
	
	.link-card-content{
		display: block;
		color: black;
		font-size: 24px;

		img {
			padding-right: 16px;
		}
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
		font-weight: 700;
		span {
			font-size: 24px;
		}
	}
	&:hover {
		p {
			text-decoration: none;
		}
	}

	background-color: $LinkCard-evc-card-background-color;
	border: 1px solid #f2f2f2;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
	align-items: center;
	p {
		font-size: 0.7rem;
	} 
}
@media (max-width: 1000px) {
	.link-card {
		padding: 30px;
		margin: 8px 0px;
    width: 350px;
	}
}
@media (min-width: 768px) {
	.link-card {
		flex-basis: 24%;
		margin: 1%;
	}
	.evc-card {
		margin: 1%;
		.evc-card-content{
			display: block;
		}
	}
}
@media (max-width: 576px) {
	.link-card {
		margin: 2px;
		height: 150px;
		width: 315px;
		padding: 22px;
		margin: 10px;
		p span {
				font-size: 24px;
		}
		.link-card-content {
			display: flex;
			.link-icon{
				margin-top: -20px;
			}
		}
		.evc-card {
			p {
				font-size: 0.85rem;
			}
			padding: 2rem 1rem;
		}
	}
}
@media (max-width: 340px) {
	.link-card {
		p span {
				font-size: 23px;
		}
	}
}