@import "src/client_customizations/scss/client_master.scss";

.filter-btns {
	display: inline;
}

.btn-cost {
	padding: 10px 35px;
	border-radius: 0;
	border: 1px solid lightgray;
	margin: 10px 0 	5px 0;
}
