@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	border-bottom: $Header-border-bottom;
	a {
		color:$Header-link-color;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			// img {
			// 	width: 160px;
			// }
		}
		.navbar-collapse {
			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}

      .navbar-nav {
        align-items: center;
      }

      .dropdown-item {
        border-radius: 0;
      }
		}

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
	}
	.nav-item {
		margin: 5px;
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
.zappyride-col-2 {
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	margin-left: 15px;
	margin-right: -195px;
}
.zappyride-col-1 {
	display: flex;
}
.header-box {
	display: flex;
	padding-top: 0.75rem;
 	padding-bottom: 0.75rem;
}


.logo-margin {
	margin-right: -18px;
}
.logo-line {
	margin-top: 10px;
}
.top-col-2 {
	width: 25%
}
.bot-col-2 {
	width: 35%;
}
@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}
