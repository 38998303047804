@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
	.compared-vehicle-container {
		padding-top: 20px;
	}
	.single-vehicle {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.subtitle {
	padding-top: 10px;
	color: $gray;
}

.title {
	font-size: 30px;
}