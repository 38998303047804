@import "src/client_customizations/scss/client_master.scss"; 

.homepage-ev-card{
  .homepage-ev-card-btn {
    padding: 40px;
    color: black;
  }
  .h2 {
    font-size: 20px;
  }
  .h3 {
    font-size: 12px;
  }
}

.EVCardTop {
  position: relative;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .badge-locally-available, .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

}

@media (max-width: 330px) {
  .homepage-evcard-btn {
    font-size: 10px !important;
  }
}


.homepage-ev-card {
  flex-basis: 49%;
  &:focus {
    box-shadow: $focus-box-shadow;
    outline: 0;
  }
  background-color: $PageLayout-evc-card-focus-box-shadow;
  font-size: $PageLayout-evc-card-font-size;
  border: $PageLayout-evc-card-border;
  color:$PageLayout-evc-card-color;
  display: inline-block;
  cursor: pointer;
  &:hover {
    border: $PageLayout-evc-card-hover-border;
    box-shadow: $PageLayout-evc-card-hover-box-shadow;
    color: $PageLayout-evc-card-hover-color;
    text-decoration: none;
    cursor: default;
  }
}

.HomepageEVCardBottomWithLink {
  text-align: center;
  // padding: 0 0.75rem 1rem 0.75rem;
  padding: 20px 0px;

  .homepage-evcard-btn {
    font-size: 16px;
    padding: 12px 20px;
    background-color: #EEEEEE;
    color: black;
    
    &:hover {
      background-color: #000000;
      color: white;
      text-decoration: none;
    }
  }
}

@media (min-width: 576px) {
  .homepage-evcard-btn {
    font-size: 16px;
    padding: 12px 40px;
    background-color: #EEEEEE;
    
    &:hover {
      background-color: #000000;
      color: white;
      text-decoration: none;
    }
  }

  .homepage-ev-card {
    flex-basis: 30%;
  }
  .EVCardTop {
    padding: 15px 15px 10px 15px;
  }

  .EVCardBottom > * {
    padding: 10px 40px;
  }

  .EVCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}