@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	flex-basis: 49%;
	margin-right: 1%;
	margin-bottom: 2%;
	border-radius: 
	$IncentiveCard-card-border-radius;
	.pl-5{
		padding: 0 1rem !important;
	}
}
.IncentiveCardBody {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	height: fit-content;
	.card-title {
		margin-bottom: 1.3rem;
		color: $IncentiveCard-card-title-color;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	text-align: left;
	font-size: 12px;
	padding: 1.1rem;
}
#form-button{
	padding: 15px 30px 15px 30px;
    font-size: 14px;
    border-radius: 0.25rem;
    margin: 0 26%;
}
@media all and (min-width: 760px) {
	.IncentiveCard {
		flex-basis: 23%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}
	.IncentiveCardBottom {
		padding: 1.1rem;
	}
	.card-title{
		hyphens: auto;
	}
}
@media all and (device-width: 768px) {
	/* For general iPad layouts */
	.IncentiveCard {
		flex-basis: 23%;
		min-width: 212px;
	}
	.card-title{
		hyphens: auto;
	}
  }
@media all and (max-width: 390px) {
		.IncentiveCardBody {
			.card-title {
			max-width: 130px;
			font-size: 23px;
			hyphens: auto;
			}
		}
	}
@media only screen and (device-width: 414px), (device-width: 411px)  { 
	.IncentiveCardBody {
		.card-title {
		max-width: 181px;
		hyphens: auto;
		}
	}
}
@media only screen and (device-width: 320px)  { 
	h6{
		font-size: .85rem;
	}
	.IncentiveCard{
		max-width: 140px;
	}
	.IncentiveCardBody {
		.card-title{
			max-width: 107px;
			font-size: 22px;
			hyphens: auto;
		}
	}
}