@import "src/client_customizations/scss/client_master.scss";



.charge-btn {
	padding: 12px 30px;
	border-radius: 0;
	border: 1px solid lightgray;
}
.first{
	margin-right: 10px;
}
.charge-btns {
		text-align: center;
	}

