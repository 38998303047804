@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	color: $GenericFooter-color;
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
}
	a {
		font-family: "Uber-Move-Light";
		// transition: 0.3s all ease;
		// -webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			text-decoration: none;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
}
.nav-links {
	
	display: flex;
	justify-content: space-around;
}
.footer-title{
	display: flex;
    width: 90px;
    margin-left: -18px;
    height: 45px;
}

@media (max-width: 576px) {
	.img-fluid {
    	// left: -160px;
	}
	.nav-links {
		flex-direction: column;
		margin-left: 10px;
	}
	.support-links{
		display: flex;
		flex-direction: column;
		margin-left: -20px;
	}
	.copyright {
		margin-left: 10px
	}
}
.support-links {
	a {
		color: #9d9d9d;
		margin: 0px 20px;
	}
}
.support-links:hover {
	color: #fff
}
.disclaimer_container {
	// text-align: center;

	div {
		   background-color:transparent !important;

		   img {
				  max-width: 100%;
				  margin:0 0 25px;
		   }

		   p {
				  color:white;
		   }
	}
}
