.FeedbackModal {
  position: fixed;
  position: sticky;
  top: 50%;
  transform: rotate(270deg);
  transform-origin: left top 0;
  height: 0;
  z-index: 10;
  color: white;
}
.no-arrow {
  background: none;
}
@media (max-width: 991px) {
  .FeedbackModal {
    top: calc(100% - 30px - 112px);
  }
}

.btn-pge:hover {
  background-color: #333333;
  border: solid #333333;
  color: white;
}
.feedback-btn {
  border: solid black;
  border-radius: 0;
  padding: 0.25em 1.75em;
  background-color: black;
  z-index: 100;
  color: white;
}
#feedback {
  position: fixed;
  top: 50%;
  left: -48px;
  transform: rotate(90deg);
}
#feedback-fr {
  position: fixed;
  top: 50%;
  left: -84px;
  transform: rotate(90deg);
}
#feedback:hover {
  cursor: pointer;
  color: white;
}

@media (max-width: 500px) {
  #feedback {
    position: fixed;
    top: 93%;
    left: 32%;
    bottom: 0%;
    position: 100;
    display: flex;
    padding-top: 5px;
    text-align: revert;
    transform: rotate(0deg);
    background-color: rgba(0, 0, 0, 0.85);
  }
}
